import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import TextField from '@mui/material/TextField';
import React, { useEffect, useState } from 'react';
import { SearchFieldProps } from './SearchField.types';
import { TEST_ID } from '../../../config/test-fields-ids.config';
import './SearchField.scss';
import { DeleteOutline } from '@mui/icons-material';
import { MAX_SEARCH_QUERY_LENGTH } from '../../../config/config';

export const SearchField: React.FC<SearchFieldProps> = ({
  searchQuery,
  setSearchQuery,
  selected,
  onApply = () => {},
  onDelete,
  onClick = () => {},
  bookmarkMode,
}) => {
  const [searchQueryInput, setSearchQueryInput] = useState<string>(searchQuery);

  useEffect(() => {
    setSearchQueryInput(searchQuery);
  }, [searchQuery]);

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      setSearchQuery(searchQueryInput);
      onApply();
    }
  };

  return (
    <div className={`search-field-wrapper ${bookmarkMode ? 'search-field-wrapper--bookmark' : ''}`}>
      <div className='search-field__input-wrapper'>
        <TextField
          className={`search-field ${selected ? 'search-field--selected' : ''}`}
          data-test-id={TEST_ID.SEARCH_QUERY.FIELD}
          placeholder='Type to search'
          size='small'
          value={searchQueryInput}
          onClick={onClick}
          onChange={(e) => setSearchQueryInput(e.target.value)}
          onBlur={() => {
            setSearchQuery(searchQueryInput);
          }}
          onKeyDown={handleKeyDown}
          inputProps={{
            maxLength: MAX_SEARCH_QUERY_LENGTH,
          }}
          InputProps={{
            endAdornment: (
              <>
                {searchQueryInput && (
                  <>
                    {bookmarkMode && (
                      <IconButton
                        className='search-field__close-end-icon--visible'
                        onClick={() => {
                          setSearchQuery(searchQueryInput);
                        }}
                      >
                        <SearchIcon />
                      </IconButton>
                    )}
                    <IconButton
                      data-test-id={TEST_ID.SEARCH_QUERY.FIELD_CLEAN}
                      className='search-field__close-end-icon--visible'
                      onClick={() => {
                        setSearchQueryInput('');
                        setSearchQuery('');
                        onApply();
                      }}
                    >
                      <CloseIcon />
                    </IconButton>
                  </>
                )}
              </>
            ),
          }}
        />

        {!bookmarkMode && searchQueryInput.length > 1900 && (
          <div className='search-field__counter'>
            {searchQueryInput.length}/{MAX_SEARCH_QUERY_LENGTH}
          </div>
        )}
      </div>
      {!bookmarkMode && (
        <div className='search-field__after-icon'>
          {onDelete && selected ? (
            <IconButton
              data-test-id={TEST_ID.SEARCH_QUERY.FIELD_CLEAN}
              className='search-field__close-end-icon--visible'
              onClick={onDelete}
            >
              <DeleteOutline />
            </IconButton>
          ) : null}
        </div>
      )}
    </div>
  );
};
