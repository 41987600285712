import Mark, { MarkOptions } from 'mark.js';
import { getHTMLElement } from './HtmlViewer.highlight';
import { RefObject, useEffect } from 'react';
import { useAppSelector } from '../../../store/hooks';
import { ClauseResponse } from '../../../store/files/clauses/clauses.list.types';
import viewer from './FileViewer.module.scss';
import { useSnackbar } from 'notistack';
import { ErrorMessages } from '../../../services/errors.service.types';
import { uiSelectors } from '../../../store/ui/ui.selectors';
import { Highlight } from '../../../store/files/documents/documents.list.types';
import { chunk } from 'lodash';
import { documentChatSelectors } from '../../../store/files/chat/documentChat.selectors';

const TAG = 'clause';
const SIMILAR = 'similar';
export const clauseStyles = `<style>${TAG} {background: ${viewer.clauseBackground};}</style>`;

const MARGIN_MARKER_CLASSNAME = 'margin-marker';
const marginMarker = (element: HTMLElement, first: number, last: number, scale?: number) => {
  const width = 10;
  const top = first;
  const height = last - first;

  const scaled = (num: number) => (scale ? num / scale : num);

  const margin = document.createElement('div');
  margin.classList.add(MARGIN_MARKER_CLASSNAME);
  margin.style.position = 'absolute';
  margin.style.left = '10px';
  margin.style.width = scaled(width) + 'px';
  margin.style.top = scaled(top) + 'px';
  margin.style.height = scaled(height) + 'px';
  margin.style.background = viewer.clauseBackground;

  element.append(margin);
};

const options: MarkOptions = {
  accuracy: 'partially',
  element: TAG,
  className: '',
  ignorePunctuation: '- –,.()?:;|/\\§0123456789"\'„“'.split(''),
  ignoreJoiners: true,
  acrossElements: true,
  separateWordSearch: false,
  wildcards: 'enabled',
};

const optionsSimilar: MarkOptions = { ...options, element: SIMILAR };

const optionsTokens: MarkOptions = {
  accuracy: {
    value: 'exactly',
    limiters: options.ignorePunctuation,
  },
  element: TAG,
  ignorePunctuation: options.ignorePunctuation,
  ignoreJoiners: true,
  acrossElements: true,
};

export const prepareSentences = (text: string) => {
  return text
    .replaceAll('\n', ' ')
    .replaceAll(/\s{2,}/g, ' ')
    .replaceAll(' , ', ', ')
    .replaceAll(/(?<=\p{Lower})(-)\s/gu, '-')
    .replaceAll(' -', '')
    .split(/(?<=[\p{Lower})]{3,})[.?!]\s+(?=\p{Upper}|[0-9])/gu)
    .map((v) => v.trim().replaceAll(/\d+/g, '*'));
};

const calculateTop = (parent: HTMLElement, element: Element) => {
  const offset = parent instanceof HTMLDivElement ? parent.scrollTop : 0;
  return Math.abs(
    offset - parent.getBoundingClientRect().top + element.getBoundingClientRect().top
  );
};

const getStartingAndEndingSentences = (sentences: string[], count: number) => [
  ...sentences.slice(0, count),
  ...sentences.slice(-1 * count),
];

export const markClause = (instance: Mark, text: string) => {
  const preparedSentences = prepareSentences(text);
  const longClauseMode = preparedSentences.length > 10;

  const sentences = longClauseMode
    ? getStartingAndEndingSentences(preparedSentences, 3)
    : preparedSentences;

  sentences.forEach((sentence) => {
    try {
      instance.mark(sentence, options);
    } catch (e) {}
  });

  return longClauseMode;
};

export const useClauseHighlight = (
  ref: RefObject<HTMLIFrameElement | HTMLDivElement | null>,
  rendered: boolean,
  clauses?: ClauseResponse[],
  scale?: number,
  similar?: Highlight[],
  chatMode?: boolean
) => {
  const scrollToClauseInDoc = useAppSelector(uiSelectors.selectScrollToClauseInDoc);
  const quote = useAppSelector(documentChatSelectors.selectQuote);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (!rendered) return;

    const element = getHTMLElement(ref.current);
    if (!element) return;

    const instance = new Mark(element);
    instance.unmark(options);
    instance.unmark(optionsSimilar);
    element.querySelectorAll(`.${MARGIN_MARKER_CLASSNAME}`).forEach((e) => e.remove());

    if (!scrollToClauseInDoc && !similar && !(chatMode && quote)) return;

    let longClauseMode = false;
    if (chatMode && quote) {
      markClause(instance, quote);
    } else if (scrollToClauseInDoc) {
      const clause = clauses?.find(({ ClauseId }) => ClauseId === scrollToClauseInDoc);
      if (!clause) return;

      longClauseMode = markClause(instance, clause.Text);
    } else if (similar) {
      markSimilar(instance, similar, element);
    }

    const marks = element.querySelectorAll(TAG);

    if (marks.length) {
      setTimeout(() => {
        const top = calculateTop(element, marks[0]);
        element.scrollTo({ top, behavior: 'smooth' });

        if (longClauseMode) {
          marginMarker(element, top, calculateTop(element, marks[marks.length - 1]), scale);
        }
      });
    } else {
      enqueueSnackbar(
        chatMode ? ErrorMessages.QuoteHighlightError : ErrorMessages.ClauseHighlightError,
        { variant: 'warning' }
      );
    }
  }, [
    chatMode,
    clauses,
    enqueueSnackbar,
    quote,
    ref,
    rendered,
    scale,
    scrollToClauseInDoc,
    similar,
  ]);
};

const markSimilar = (instance: Mark, similar: Highlight[], element: HTMLElement) => {
  similar.forEach((highlight, index) => {
    const parts = [highlight.FragmentText.replaceAll(/ +/g, ' ').trim()];

    parts.forEach((part) => {
      instance.mark(part, {
        ...optionsSimilar,
        each(element: Element) {
          element.setAttribute('group', index.toString());
        },
      });
    });

    const marks = element.querySelectorAll<HTMLElement>(`${SIMILAR}[group="${index.toString()}"]`);
    const marksInstance = new Mark(Array.from(marks));
    marksInstance.mark(highlight.Tokens, optionsTokens);
  });
};

export const getHighlightParts = ({ FragmentText, Tokens }: Highlight) => {
  const words = FragmentText.toLocaleLowerCase()
    .replaceAll(/[^\p{Letter} ]/gu, '')
    .replaceAll(/ +/g, ' ')
    .trim()
    .split(' ');

  const highlightedWords: string[][] = [[]];

  words.forEach((word) => {
    if (Tokens.includes(word)) {
      highlightedWords[highlightedWords.length - 1].push(word);
    } else if (highlightedWords[highlightedWords.length - 1].length) {
      highlightedWords.push([]);
    }
  });

  return highlightedWords
    .flatMap((v) => chunk(v, 5))
    .filter((v) => v.length > 4)
    .map((v) => v.join(' '));
};
