import { LanguageToggleSwitchTypes } from '../../StaticComponents/LanguageToggleSwitch/LanguageToggleSwitch.types';
import { useAppSelector } from '../../../store/hooks';
import { uiSelectors } from '../../../store/ui/ui.selectors';
import { Order } from '../../StaticComponents/FileViewer/useDocumentCompare';

export const useDetermineViewerLanguage = (
  language: LanguageToggleSwitchTypes,
  order: Order | Number | undefined,
  translatedUrl: string | undefined
) => {
  const isLanguageLocked = useAppSelector(uiSelectors.selectLockLanguage);
  const lockedLanguage = useAppSelector(uiSelectors.selectLockedLanguage);

  const languageToSwitch = isLanguageLocked && order !== undefined ? lockedLanguage : language;
  const shouldForceOriginalLanguage =
    languageToSwitch === LanguageToggleSwitchTypes.English &&
    (!translatedUrl || translatedUrl === '');
  return shouldForceOriginalLanguage ? LanguageToggleSwitchTypes.Original : languageToSwitch;
};
