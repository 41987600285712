import { ChatMessageStreamProps } from './ChatWindow.types';
import React, { useEffect, useRef } from 'react';
import { useAppSelector } from '../../store/hooks';
import { Sender } from '../../store/files/chat/documentChat.types';
import { ChatMessageLLM } from './ChatMessageLLM';

export const ChatMessageStream = ({ messagesSelector }: ChatMessageStreamProps) => {
  const messages = useAppSelector(messagesSelector);

  const messagesEndRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [messages]);

  return (
    <div className='chat-message-stream'>
      {messages.map(({ sender, text, preamble, quotes }, index) => {
        switch (sender) {
          case Sender.User:
            return (
              <div key={index} className='chat-message-user'>
                <div className='chat-message-user-message'>{text}</div>
              </div>
            );
          case Sender.LLM:
            return (
              <div key={index} className='chat-message-llm'>
                <div className='chat-message-llm-message'>
                  <ChatMessageLLM text={text} preamble={preamble} quotes={quotes} />
                </div>
              </div>
            );
          default:
            return null;
        }
      })}

      <div ref={messagesEndRef}></div>
    </div>
  );
};
