import { USER_POOL_ID, USER_POOL_WEB_CLIENT_ID, OAUTH_DOMAIN } from './envs';

class MemoryStorage {
  static storage: Map<string, string> = new Map<string, string>();

  static setItem(key: string, value: string): string {
    MemoryStorage.storage.set(key, value);
    return MemoryStorage.storage.get(key) || '';
  }

  static getItem(key: string): string {
    return MemoryStorage.storage.get(key) || '';
  }

  static removeItem(key: string): void {
    MemoryStorage.storage.delete(key);
  }

  static clear(): void {
    MemoryStorage.storage = new Map<string, string>();
  }
}

export const awsAuthConfig = {
  userPoolId: USER_POOL_ID,
  userPoolWebClientId: USER_POOL_WEB_CLIENT_ID,
  oauth: {
    domain: OAUTH_DOMAIN,
    redirectSignIn: window.location.origin,
    redirectSignOut: window.location.origin + '/logout',
    responseType: 'code',
  },
  storage: MemoryStorage,
};
