import { RootState } from '../../store';

const selectDocumentChat = (state: RootState) => state.documentChat;

const selectError = (state: RootState) => selectDocumentChat(state).error;

const selectLoading = (state: RootState) => selectDocumentChat(state).loading;

const selectMessages = (state: RootState) => selectDocumentChat(state).messages;

const selectModalChat = (state: RootState) => selectDocumentChat(state).modal;

const selectQuote = (state: RootState) => selectDocumentChat(state).quote;

const selectSpinner = (state: RootState) => selectDocumentChat(state).spinner;

export const documentChatSelectors = {
  selectError,
  selectLoading,
  selectMessages,
  selectModalChat,
  selectQuote,
  selectSpinner,
};
