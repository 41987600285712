const MARGIN_CLASS_NAME = 'margin';
const OVERFLOW_CLASS_NAME = 'overflow';
const WIDTH_DATA_ATTR = 'data-width';

const defaultStyles = `<style>
.${MARGIN_CLASS_NAME} { position: absolute; top: 0; left: 600px; right: 0; }
div.${OVERFLOW_CLASS_NAME} { overflow: hidden; outline: 1px solid lightgrey; }
div.${OVERFLOW_CLASS_NAME}:hover { height: var(--height) !important; z-index: 9999; background: #fff; }
div.${OVERFLOW_CLASS_NAME}[${WIDTH_DATA_ATTR}]:hover { width: var(--width) !important; }
</style>`;

const STATS_CLASS_NAME = 'stats';
const BORDERS_CLASS_NAME = 'borders';
const ORIG_DIMENSIONS_CLASS_NAME = 'orig-dimensions';
const ORIG_SCROLL_DIMENSIONS_CLASS_NAME = 'orig-scroll-dimensions';
const NO_CHANGE_CLASS_NAME = 'no-change';
const SCROLL_HEIGHT_CLASS_NAME = 'scroll-height';
const RESIZED_WIDTH_CLASS_NAME = 'resized-width';
const RESIZED_HEIGHT_CLASS_NAME = 'resized-height';

const debugStyles = `<style>
.${STATS_CLASS_NAME} { margin: 0; position: absolute; top: 0; right: 0; font-size: 10px; list-style: none; }
.${STATS_CLASS_NAME} li { margin: 3px; }
.${BORDERS_CLASS_NAME} .${ORIG_DIMENSIONS_CLASS_NAME} { outline: 1px solid black; }
.${BORDERS_CLASS_NAME} .${ORIG_SCROLL_DIMENSIONS_CLASS_NAME} { outline: 1px solid lightgrey; }
.${BORDERS_CLASS_NAME} span { outline: 1px solid lightgoldenrodyellow; }
.${BORDERS_CLASS_NAME} .${NO_CHANGE_CLASS_NAME} { outline: 1px solid green; }
.${BORDERS_CLASS_NAME} .${SCROLL_HEIGHT_CLASS_NAME} { outline: 1px solid yellow; }
.${BORDERS_CLASS_NAME} .${RESIZED_WIDTH_CLASS_NAME} { outline: 1px solid orange; }
.${BORDERS_CLASS_NAME} .${RESIZED_HEIGHT_CLASS_NAME} { outline: 1px solid magenta; }
.${BORDERS_CLASS_NAME} .${OVERFLOW_CLASS_NAME} { outline: 1px solid red; }
.${BORDERS_CLASS_NAME} .${MARGIN_CLASS_NAME} { outline: 1px solid darkblue; }
</style>`;

export const customStyles = defaultStyles;

function addDebugStyles(el: HTMLElement) {
  const style = document.createElement('span');
  style.innerHTML = debugStyles;
  el.append(style.childNodes.item(0));
}

function addMargin(el: HTMLElement) {
  const div = document.createElement('div');
  div.classList.add(MARGIN_CLASS_NAME);
  div.style.height = `${el.scrollHeight}px`;
  el.append(div);
}

export function cleanupUnnecessaryBrsFromSpans(el: HTMLElement) {
  const spans = el.getElementsByTagName('span');
  for (let i = 0; i < spans.length; i++) {
    spans[i].innerHTML = spans[i].innerHTML
      .replaceAll('<br> <br>', '<br>')
      .replaceAll('<br><br>', '<br>');

    if (i === 0) {
      spans[i].innerHTML = spans[i].innerHTML.replaceAll(/^\s*<br>/g, '');
    }
    if (i === spans.length - 1) {
      spans[i].innerHTML = spans[i].innerHTML.replaceAll(/<br>\s*$/g, '<br>');
    }
  }
}

const scrollOverflowOffset = 5;

function hasHorizontalOverflow(el: HTMLElement) {
  return el.scrollHeight - el.offsetHeight > scrollOverflowOffset;
}

type Rect = Pick<DOMRect, 'top' | 'left' | 'width' | 'height'>;
export function calcCollision(a: Rect, b: Rect) {
  return !(
    a.top > b.top + b.height ||
    b.top > a.top + a.height ||
    a.left > b.left + b.width ||
    b.left > a.left + a.width
  );
}

const nearbyDivsOffset = 400;

function getNearbyDivs(current: HTMLDivElement, divs: NodeListOf<HTMLDivElement>) {
  let nearby: HTMLDivElement[] = [];
  const currentRect = current.getBoundingClientRect();
  currentRect.width = currentRect.width + nearbyDivsOffset;
  currentRect.height = currentRect.height + nearbyDivsOffset;

  divs.forEach((div) => {
    if (current === div) return;
    if (
      div.classList.contains(MARGIN_CLASS_NAME) ||
      calcCollision(currentRect, div.getBoundingClientRect())
    ) {
      nearby.push(div);
    }
  });
  return nearby;
}

function getCollisions(current: HTMLDivElement, divs: HTMLDivElement[]) {
  let collisions: HTMLDivElement[] = [];
  divs.forEach((div) => {
    if (
      current !== div &&
      calcCollision(current.getBoundingClientRect(), div.getBoundingClientRect())
    ) {
      collisions.push(div);
    }
  });
  return collisions;
}

function getNumber(str: string) {
  const numStr = str.replace('px', '');
  return numStr ? parseInt(numStr) : 0;
}

const resizeWidthLimit = 60;
const resizeWidthStep = 10;

function resizeWidth(el: HTMLDivElement, divs: HTMLDivElement[]) {
  let n = 0;
  while (hasHorizontalOverflow(el) && n < resizeWidthLimit) {
    const width = getNumber(el.style.width);
    el.style.width = `${width + resizeWidthStep}px`;
    if (getCollisions(el, divs).length) {
      el.style.width = `${width}px`;
      break;
    }
    n++;
  }
}

const resizeHeightLimit = 25;
const resizeHeightStep = 5;

function resizeHeight(el: HTMLDivElement, divs: HTMLDivElement[]) {
  let n = 0;
  while (hasHorizontalOverflow(el) && n < resizeHeightLimit) {
    const height = getNumber(el.style.height);
    el.style.height = `${height + resizeHeightStep}px`;
    if (getCollisions(el, divs).length) {
      el.style.height = `${height}px`;
      break;
    }
    n++;
  }
}

const smallDivWidth = 100;

export function prettifyHtml(body?: HTMLElement, debug = false) {
  if (!body) return;

  const start = debug ? performance.now() : undefined;

  if (debug) {
    addDebugStyles(body);
    body.classList.add(BORDERS_CLASS_NAME);
  }

  addMargin(body);

  const divs = body.querySelectorAll('div');
  divs?.forEach((div) => {
    // 1. Remove br tags from spans and check for height overflow
    cleanupUnnecessaryBrsFromSpans(div);

    if (!hasHorizontalOverflow(div)) {
      if (debug) {
        div.classList.add(NO_CHANGE_CLASS_NAME);
      }
      return;
    }

    // Add copies of div and scroll dimensions to compare with processing results
    if (debug) {
      addOrigElement(div);
    }

    // Get nearby divs to avoid iterating over all divs
    const nearby = getNearbyDivs(div, divs);
    const originalHeight = div.style.height;

    // 2. Set div height to scroll height and check for collisions
    div.style.height = `${div.scrollHeight}px`;

    // Try to resize width for smaller divs (i.e. page numbers)
    if (div.offsetWidth > smallDivWidth && !getCollisions(div, nearby).length) {
      if (debug) {
        div.classList.add(SCROLL_HEIGHT_CLASS_NAME);
      }
      return;
    }

    // 3. Reset height, try to resize width to remove overflow and set height to scroll height
    div.style.height = originalHeight;
    resizeWidth(div, nearby);
    div.style.height = `${div.scrollHeight}px`;

    if (!getCollisions(div, nearby).length) {
      if (debug) {
        div.classList.add(RESIZED_WIDTH_CLASS_NAME);
      }
      return;
    }

    // 4. Reset height and try to resize height as much as possible
    div.style.height = originalHeight;
    resizeHeight(div, nearby);

    if (!hasHorizontalOverflow(div)) {
      if (debug) {
        div.classList.add(RESIZED_HEIGHT_CLASS_NAME);
      }
      return;
    }

    // 5. There is still an overflow, add height (and width for small divs) for hover
    div.style.setProperty('--height', div.scrollHeight.toString());
    if (div.scrollWidth < smallDivWidth) {
      div.setAttribute(WIDTH_DATA_ATTR, '');
      div.style.setProperty('--width', div.scrollWidth.toString());
    }

    div.classList.add(OVERFLOW_CLASS_NAME);
  });

  if (debug && start) {
    addStats(body, performance.now() - start);
  }
}

function addOrigElement(el: HTMLDivElement) {
  const u1 = document.createElement('u');
  u1.style.display = el.style.display;
  u1.style.position = el.style.position;
  u1.style.top = el.style.top;
  u1.style.left = el.style.left;
  u1.classList.add(ORIG_DIMENSIONS_CLASS_NAME);
  u1.style.width = el.style.width;
  u1.style.height = el.style.height;
  el.before(u1);

  const u2 = document.createElement('u');
  u2.style.display = el.style.display;
  u2.style.position = el.style.position;
  u2.style.top = el.style.top;
  u2.style.left = el.style.left;
  u2.classList.add(ORIG_SCROLL_DIMENSIONS_CLASS_NAME);
  u2.style.width = `${el.scrollWidth}px`;
  u2.style.height = `${el.scrollHeight}px`;
  el.before(u2);
}

function addStats(body: HTMLElement, rendered: number) {
  const all = body.querySelectorAll('div').length;
  const noChange = body.querySelectorAll(`div.${NO_CHANGE_CLASS_NAME}`).length;
  const scrollHeight = body.querySelectorAll(`div.${SCROLL_HEIGHT_CLASS_NAME}`).length;
  const resizedWidth = body.querySelectorAll(`div.${RESIZED_WIDTH_CLASS_NAME}`).length;
  const resizedHeight = body.querySelectorAll(`div.${RESIZED_HEIGHT_CLASS_NAME}`).length;
  const overflow = body.querySelectorAll(`div.${OVERFLOW_CLASS_NAME}`).length;
  const overflowWidth = body.querySelectorAll(`div[${WIDTH_DATA_ATTR}]`).length;

  const html = `
    <li>all: ${all}</li>
    <li class="${NO_CHANGE_CLASS_NAME}">no changes: ${noChange}</li>
    <li class="${SCROLL_HEIGHT_CLASS_NAME}">height to scrollHeight: ${scrollHeight}</li>
    <li class="${RESIZED_WIDTH_CLASS_NAME}">resized width: ${resizedWidth}</li>
    <li class="${RESIZED_HEIGHT_CLASS_NAME}">resized height: ${resizedHeight}</li>
    <li class="${OVERFLOW_CLASS_NAME}">overflow: ${overflow} (${overflowWidth})</li>
    <li>rendered: ${rendered} ms</li>
  `;

  const el = document.createElement('ul');
  el.classList.add(STATS_CLASS_NAME);
  el.innerHTML = html;
  body.append(el);
}
