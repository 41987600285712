import React, { useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react';
import UploadService from '../../../store/files/upload/upload.service';
import { FileViewerProps } from './FileViewer.types';
import { FileLoader } from './FileLoader';
import { useFileLoader } from './FileLoader.hook';
import './FileViewer.scss';
import { Document, Page, pdfjs } from 'react-pdf/dist/cjs';
import type { PDFDocumentProxy } from 'pdfjs-dist';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import { useZoomSwitch } from './FileViewer.helpers';
import { ViewerToolbar } from './ViewerToolbar/ViewerToolbar';
import { copyEventListener, useTextSelectionDispatch } from './FileViewer.hook';
import { useHighlight } from './HtmlViewer.highlight';
import { customTextRenderer, generatePages, handleFnOnAllPagesRendered } from './PdfViewer.helpers';
import { PdfDocumentProps, PdfViewerHelperProps, PdfViewerHelperRef } from './PdfViewer.types';
import { useClauseHighlight } from './HtmlViewer.clause-highlight';
import { setIsPdfRendered } from '../../../store/files/upload/upload.slice';
import { useAppDispatch } from '../../../store/hooks';
import { ScrollSyncPane } from 'react-scroll-sync';
import { DOCUMENT_ZOOM_SCALES } from '../../../config/config';

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  'pdfjs-dist/build/pdf.worker.min.mjs',
  import.meta.url
).toString();

const PdfViewer = ({
  loading,
  error,
  url,
  keyword,
  method,
  searchOption,
  clauses,
  zoomScales = DOCUMENT_ZOOM_SCALES,
  centered,
  similar,
  chatMode,
}: FileViewerProps) => {
  const { isLoading, isError, setLoading, setError } = useFileLoader(loading, error);
  const ref = useRef<HTMLDivElement | null>(null);
  const helperRef = useRef<PdfViewerHelperRef>(null);
  const { scale, handleZoom } = useZoomSwitch(url, zoomScales);
  const dispatch = useAppDispatch();

  const [pdfRendered, setPdfRendered] = useState(false);

  useEffect(() => {
    dispatch(setIsPdfRendered(pdfRendered));
  }, [pdfRendered, dispatch]);

  const onRenderSuccess = useCallback(() => {
    helperRef.current?.initHighlight();
    setPdfRendered(true);
  }, []);

  const { handleTextSelection } = useTextSelectionDispatch();

  useEffect(() => {
    const copyFunction = copyEventListener(window);
    document.addEventListener('copy', copyFunction);

    return () => {
      document.removeEventListener('copy', copyFunction);
    };
  }, []);

  useEffect(() => {
    if (isLoading || scale > -1) setPdfRendered(false);
  }, [isLoading, scale]);

  useEffect(() => {
    if (url) {
      setLoading(true);
      setError(false);
      UploadService.preflightDocumentDownload(url)
        .catch(() => setError(true))
        .then(() => setLoading(false));
    }
  }, [setError, setLoading, url]);

  return (
    <div className={`file-viewer file-viewer--pdf ${centered ? 'file-viewer--pdf-centered' : ''}`}>
      {isLoading || isError || !url ? (
        <FileLoader loading={isLoading} error={isError} />
      ) : (
        <>
          <ScrollSyncPane attachTo={ref}>
            <PdfDocument
              ref={ref}
              url={url}
              scale={scale}
              onMouseUp={handleTextSelection}
              onRenderSuccess={onRenderSuccess}
            />
          </ScrollSyncPane>

          <PdfViewerHelper
            ref={helperRef}
            pdfRef={ref}
            rendered={pdfRendered}
            keyword={keyword}
            clauses={clauses}
            handleZoom={handleZoom}
            searchOption={searchOption}
            method={method}
            similar={similar}
            chatMode={chatMode}
          />
        </>
      )}
    </div>
  );
};

const PdfDocument = React.memo(
  React.forwardRef<HTMLDivElement, PdfDocumentProps>(
    ({ url, scale, onRenderSuccess, onMouseUp }, ref) => {
      const [pages, setPages] = useState<number[]>([]);
      const renderedPages = useRef<number[]>([]);

      const handleLoadSuccess = useCallback(({ numPages }: PDFDocumentProxy) => {
        setPages(generatePages(numPages));
      }, []);

      const handleRenderTextLayerSuccess = useCallback(
        (page: number) => {
          renderedPages.current = handleFnOnAllPagesRendered(
            renderedPages.current,
            page,
            pages.length,
            onRenderSuccess
          );
        },
        [onRenderSuccess, pages.length]
      );

      return (
        <Document
          className='file-viewer--document'
          inputRef={ref}
          file={url}
          loading=''
          onLoadSuccess={handleLoadSuccess}
          onMouseUp={onMouseUp}
        >
          {pages.map((page) => (
            <Page
              key={`page_${page}`}
              pageNumber={page}
              scale={scale}
              loading=''
              customTextRenderer={customTextRenderer}
              onRenderTextLayerSuccess={() => handleRenderTextLayerSuccess(page)}
            />
          ))}
        </Document>
      );
    }
  )
);

const PdfViewerHelper = React.forwardRef<PdfViewerHelperRef, PdfViewerHelperProps>(
  (
    { pdfRef, rendered, handleZoom, keyword, method, searchOption, clauses, similar, chatMode },
    ref
  ) => {
    const { handleHighlight, handleSwitch } = useHighlight(pdfRef, keyword, method);
    useClauseHighlight(pdfRef, rendered, clauses, undefined, similar, chatMode);

    useImperativeHandle(ref, () => ({
      initHighlight() {
        handleHighlight();
      },
    }));

    return (
      <ViewerToolbar
        handleZoom={handleZoom}
        handleSwitch={handleSwitch}
        searchOption={searchOption}
      />
    );
  }
);

export default React.memo(PdfViewer);
