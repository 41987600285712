import { Auth } from 'aws-amplify';
import { HubCapsule } from '@aws-amplify/core';
import { AUTH_PROVIDERS } from '../../config/envs';
import { RoutePath } from '../../services/route.service';
import { uiSlice } from '../../store/ui/ui.slice';
import { PromiseRunner } from './PromiseRunner';
import { AuthorizationResponse } from '../../contexts/authorization.service.types';

const COGNITO_PROVIDER = 'COGNITO';
const LAST_USED_PROVIDER_KEY = 'LAST_USED_PROVIDER';

const pr = new PromiseRunner(() => Auth.currentSession());

export const getAuthorizationHeader = async () => {
  const session = await pr.run();
  const token = session.getIdToken().getJwtToken();
  return { name: 'Authorization', value: `Bearer ${token}` };
};

export const appendAuthorizationHeader = async (headers: Headers) => {
  const { name, value } = await getAuthorizationHeader();
  headers.set(name, value);
  return headers;
};

const isCognitoProvider = () => {
  const provider = window.localStorage[LAST_USED_PROVIDER_KEY];
  return provider === COGNITO_PROVIDER;
};

const INITIAL_URL_KEY = 'initialUrl';

export const setInitialPathToSessionStorage = () => {
  const pathname = window.location.pathname;
  switch (pathname) {
    case '/':
    case RoutePath.LOGIN:
    case RoutePath.NO_ACCESS:
    case RoutePath.NO_WRITE_ACCESS:
    case RoutePath.NOT_FOUND:
      break;
    case RoutePath.LOGOUT:
      window.sessionStorage.removeItem(INITIAL_URL_KEY);
      break;
    default:
      window.sessionStorage.setItem(INITIAL_URL_KEY, pathname);
  }
};

export const initialPage = window.sessionStorage.getItem(INITIAL_URL_KEY);

const getValidLastUsedProvider = () => {
  const provider = window.localStorage[LAST_USED_PROVIDER_KEY];
  return provider && AUTH_PROVIDERS.some(({ id }) => id === provider) ? provider : '';
};

export const isLastUsedProviderSet = () => !!getValidLastUsedProvider() || isCognitoProvider();

export const redirectToLastUsedProvider = () => {
  const provider = getValidLastUsedProvider();
  if (provider) {
    Auth.federatedSignIn({ customProvider: provider });
  } else if (isCognitoProvider()) {
    Auth.federatedSignIn();
  }
};

type idTokenIdentities = { providerName: string }[] | undefined;

export const storeLastUsedProvider = (data: HubCapsule) => {
  const identities: idTokenIdentities =
    data.payload.data.signInUserSession.idToken.payload.identities;
  window.localStorage[LAST_USED_PROVIDER_KEY] = identities?.length
    ? identities[0].providerName
    : COGNITO_PROVIDER;
};

export const cleanupLastUsedProvider = () => {
  window.localStorage.removeItem(LAST_USED_PROVIDER_KEY);
};

export const showReloadModal = () =>
  uiSlice.actions.showModal({
    title: 'Please sign in again',
    reason:
      'You were signed out of your account. Please press "Reload" to sign in to the GWR again.',
    label: 'Reload',
    closeAction: RoutePath.LOGIN,
  });

export const mergeAuthResponses = (
  userGwr?: AuthorizationResponse,
  userAdmin?: AuthorizationResponse
) => {
  let userData: AuthorizationResponse | undefined;
  if (userGwr && userAdmin) {
    userData = { ...userGwr };
    userData.UserGroups = userGwr.UserGroups.concat(userAdmin.UserGroups);
  } else if (userGwr) {
    userData = userGwr;
  } else if (userAdmin) {
    userData = userAdmin;
  }
  return userData;
};
