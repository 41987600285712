import React, { useEffect, useState } from 'react';
import { Alert, Button, Link } from '@mui/material';
import { Auth } from 'aws-amplify';
import './Login.scss';
import logoImg from '../../assets/icons/ergo_logo.svg';
import { AUTH_PROVIDERS } from '../../config/envs';
import { isLastUsedProviderSet, redirectToLastUsedProvider } from '../Auth/auth.helpers';
import CircularProgress from '@mui/material/CircularProgress';
import { AuthError } from './AuthError';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { TermsAndConditionsModal } from '../StaticComponents/Modals/TermsAndConditionsModal/TermsAndConditionsModal';

export const Login = () => {
  const showLoading = isLastUsedProviderSet();
  const [showLegalInfo, setShowLegalInfo] = useState(false);
  const [legalTextConfirmed, setLegalTextConfirmed] = useState(false);

  const onCloseModal = () => {
    setShowLegalInfo(false);
  };

  const handleChange = () => setLegalTextConfirmed(!legalTextConfirmed);

  useEffect(() => {
    redirectToLastUsedProvider();
  }, []);

  const renderLoginButtons = () =>
    AUTH_PROVIDERS?.map((provider) => {
      return (
        <Button
          disabled={!legalTextConfirmed}
          className='login-button'
          key={provider.id}
          type='submit'
          fullWidth
          variant='contained'
          onClick={() => Auth.federatedSignIn({ customProvider: provider.id })}
        >
          Sign in with {provider.label}
        </Button>
      );
    });

  return (
    <div className='login'>
      <div>
        <img className='ergo-logo' src={logoImg} alt='ergo-logo' />
      </div>
      <div className='login-title'>Global Wording Repository</div>
      {showLoading ? (
        <CircularProgress />
      ) : (
        <>
          <AuthError />
          <div>
            {AUTH_PROVIDERS.length ? (
              renderLoginButtons()
            ) : (
              <Alert severity='error'>No auth providers defined</Alert>
            )}
          </div>
          <div className='login-options'>
            <FormControlLabel
              control={<Checkbox onChange={handleChange} />}
              label={
                <div>
                  <span>By logging into GWR you confirm </span>
                  <Link
                    onClick={(e) => {
                      e.preventDefault();
                      setShowLegalInfo(true);
                    }}
                  >
                    terms of processing personal data
                  </Link>
                  .
                </div>
              }
            />
          </div>
        </>
      )}

      <TermsAndConditionsModal open={showLegalInfo} onClose={onCloseModal} />
    </div>
  );
};
