export const prepareText = (text: string) => {
  return text
    .split(/(\[\d+])/g)
    .filter(Boolean)
    .map((value) => {
      const quote = value[0] === '[';
      return { quote, value: quote ? value.replaceAll(/[[\]]/g, '') : value };
    });
};

export const prepareQuotes = (quotes?: string[]): Record<string, string> => {
  if (!quotes?.length) return {};

  return quotes.reduce((pv, cv) => {
    const [key, val] = cv.split('] ');
    return { ...pv, [key.replace('[', '')]: val };
  }, {});
};
