import React from 'react';
import { SnackbarProvider } from 'notistack';
import './App.scss';
import { AppRouter } from './App.router';
import { Container, createTheme, CssBaseline, ThemeProvider } from '@mui/material';
import { LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import common from '../../assets/common.module.scss';
import { Provider } from 'react-redux';
import { store } from '../../store/store';
import { AuthProvider } from '../Auth/AuthProvider';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd';
import { LicenseInfo } from '@mui/x-license-pro';
import { MUI_X_PRO_KEY } from '../../config/envs';

LicenseInfo.setLicenseKey(MUI_X_PRO_KEY);

const App = () => {
  const theme = createTheme({
    typography: {
      fontFamily: common.fontFamily,
    },
    palette: {
      primary: {
        main: common.darkRed,
      },
    },
    components: {
      MuiList: {
        styleOverrides: {
          root: { maxHeight: '70vh', overflowY: 'scroll' },
        },
      },
    },
  });

  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <AuthProvider />
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <SnackbarProvider classes={{ containerRoot: 'app-snackbar-provider-container-root' }}>
            <DndProvider backend={HTML5Backend}>
              <Container maxWidth='xl'>
                <CssBaseline />
                <AppRouter />
              </Container>
            </DndProvider>
          </SnackbarProvider>
        </LocalizationProvider>
      </ThemeProvider>
    </Provider>
  );
};
export default App;
