import { useDispatch } from 'react-redux';
import { clearSelectedText, setSelectedText } from '../../../store/files/documents/documents.slice';
import { RefObject, useCallback } from 'react';
import { timeoutPromise } from '../../../helpers/app.helpers';
import { PDF_LINE_BREAK_SPACE } from './PdfViewer.helpers';

const transformText = (selectionObj: Selection | null) => {
  return selectionObj?.toString().replaceAll('-\n', '').replaceAll('\n', ' ');
};

const transformTextFromPdf = (selectionObj: Selection | null) => {
  return selectionObj
    ?.toString()
    .replaceAll(`-${PDF_LINE_BREAK_SPACE}\n`, '')
    .replaceAll(`${PDF_LINE_BREAK_SPACE}\n`, ' ');
};

export const useTextSelectionDispatch = (ref?: RefObject<HTMLIFrameElement>) => {
  const dispatch = useDispatch();

  const handleTextSelection = useCallback(async () => {
    const win: Window = ref?.current?.contentWindow ?? window;

    await timeoutPromise(0);
    const selectionObj = win.getSelection();
    const text = !!ref?.current?.contentWindow
      ? transformText(selectionObj)
      : transformTextFromPdf(selectionObj);

    if (text) {
      dispatch(setSelectedText(text));
    } else {
      dispatch(clearSelectedText());
    }
  }, [dispatch, ref]);

  return { handleTextSelection };
};

export const copyEventListener = (window: Window) => (event: ClipboardEvent) => {
  const selectionObj = window.getSelection();
  const transformedText = window.frameElement
    ? transformText(selectionObj)
    : transformTextFromPdf(selectionObj);
  if (event.clipboardData && transformedText) {
    event.clipboardData.setData('text/plain', transformedText);
    event.preventDefault();
  }
};
