import { SearchField } from '../StaticComponents/SearchField/SearchField';
import React from 'react';
import './BookmarksSearch.scss';
import { BookmarksSearchProps } from './BookmarksSearch.types';

const BookmarksSearch = ({ openSearch, searchQuery, setSearchQuery }: BookmarksSearchProps) => {
  return (
    <>
      {openSearch && (
        <div className='bookmarks-search'>
          <SearchField searchQuery={searchQuery} setSearchQuery={setSearchQuery} bookmarkMode />
        </div>
      )}
    </>
  );
};

export default BookmarksSearch;
