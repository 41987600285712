import { IdentityProvider } from './config.types';

// API URLs
const REACT_APP_API_URL = process.env.REACT_APP_API_URL;
const REACT_APP_ADMIN_API_URL = process.env.REACT_APP_ADMIN_API_URL;

// Cognito
const REACT_APP_AUTH_PROVIDERS = process.env.REACT_APP_AUTH_PROVIDERS;
const REACT_APP_AUTH_USER_POOL_ID = process.env.REACT_APP_AUTH_USER_POOL_ID;
const REACT_APP_AUTH_USER_POOL_WEB_CLIENT_ID = process.env.REACT_APP_AUTH_USER_POOL_WEB_CLIENT_ID;
const REACT_APP_AUTH_OAUTH_DOMAIN = process.env.REACT_APP_AUTH_OAUTH_DOMAIN;

// Other
const REACT_APP_MUI_X_PRO_KEY = process.env.REACT_APP_MUI_X_PRO_KEY;
const REACT_APP_EXPERIMENTAL_ENABLED = process.env.REACT_APP_EXPERIMENTAL_ENABLED;

// index.html
const REACT_APP_VERSION = process.env.REACT_APP_VERSION;
const REACT_APP_GIT_COMMIT = process.env.REACT_APP_GIT_COMMIT;

//

export const API_URL = REACT_APP_API_URL ?? '';
export const ADMIN_API_URL = REACT_APP_ADMIN_API_URL ?? '';

const PARSED_AUTH_PROVIDERS: IdentityProvider[] = JSON.parse(REACT_APP_AUTH_PROVIDERS || '[]');
export const AUTH_PROVIDERS = PARSED_AUTH_PROVIDERS.length ? PARSED_AUTH_PROVIDERS : [];

export const USER_POOL_ID = REACT_APP_AUTH_USER_POOL_ID ?? '';
export const USER_POOL_WEB_CLIENT_ID = REACT_APP_AUTH_USER_POOL_WEB_CLIENT_ID ?? '';
export const OAUTH_DOMAIN = REACT_APP_AUTH_OAUTH_DOMAIN ?? '';

export const MUI_X_PRO_KEY = REACT_APP_MUI_X_PRO_KEY ?? '';
export const EXPERIMENTAL_ENABLED = REACT_APP_EXPERIMENTAL_ENABLED === 'true';

export const APP_VERSION = REACT_APP_VERSION ?? '';
export const GIT_COMMIT = REACT_APP_GIT_COMMIT ?? '';
